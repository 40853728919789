import React, { useState } from 'react';
import { Drawer } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import withSizes from 'react-sizes';

import { IMenuItem, IStore } from '@types';
import { follow, unfollow } from '@providers/actions';
import Favorites from '@components/menu/favorites';
import Details from './details';

import styles from './menu.module.less';
import Feedback from './feedback';
import ActionButton from './action-button';
import MenuItemCard from './menu-item-card';
import MenuItemListItem from './menu-item-list-item';
import Subscribe from '@components/subscribe';
import { useAuth } from '@providers/auth-provider';

type Props = {
  item: IMenuItem;
  isMobile?: boolean;
  layout: string;
  actions: boolean;
};

const MenuItem = ({
  item,
  isMobile,
  layout = 'grid',
  actions = true,
}: Props) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const follows = useSelector((state: IStore) => state.follows);

  const [showDetails, setShowDetails] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [showFavorites, setShowFavorites] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const clearDrawer = () => {
    setShowDetails(false);
    setShowSubscribe(false);
    setShowFavorites(false);
    setShowFeedback(false);
    setShowDrawer(false);
  };
  const onClose = clearDrawer;

  // Details
  const onDetails = () => {
    clearDrawer();
    setShowDetails(true);
    setShowDrawer(true);
  };

  // Favorites
  const isFavorited = () =>
    follows.some((follow) => follow.externalId === item._id);

  const onFavorite = () => {
    clearDrawer();
    setShowSubscribe(!isAuthenticated);
    isAuthenticated && isFavorited()
      ? dispatch(unfollow(item))
      : dispatch(follow(item));
    setShowFavorites(isAuthenticated);
    actions && setShowDrawer(true);
  };

  // Feedback
  const onFeedback = () => {
    clearDrawer();
    setShowSubscribe(!isAuthenticated);
    setShowFeedback(isAuthenticated);
    setShowDrawer(true);
  };

  const getWidth = () => (isMobile ? '100%' : '50%');

  const getActions = () => [
    <ActionButton
      active={showDetails}
      icon={['fal', 'info']}
      onClick={onDetails}
      title={'Details'}
      styles={styles}
    />,
    <ActionButton
      active={showFeedback}
      icon={['fal', 'comment-alt-lines']}
      onClick={onFeedback}
      title={'Feedback'}
      styles={styles}
    />,
    <ActionButton
      active={showFavorites}
      icon={isFavorited() ? ['fas', 'heart'] : ['fal', 'heart']}
      onClick={onFavorite}
      title={'Favorite'}
      styles={styles}
    />,
  ];

  return (
    <>
      {layout === 'grid' && (
        <MenuItemCard
          item={item}
          onClick={onDetails}
          actions={getActions()}
          styles={styles}
        />
      )}
      {layout === 'list' && (
        <MenuItemListItem
          item={item}
          onClick={onDetails}
          actions={getActions()}
          styles={styles}
        />
      )}
      <Drawer
        placement="right"
        className={styles.drawer}
        closable={true}
        onClose={onClose}
        visible={showDrawer}
        width={getWidth()}
      >
        {showSubscribe && <Subscribe />}
        <Details show={showDetails} item={item} styles={styles} />
        <Feedback show={showFeedback} item={item} styles={styles} />
        <Favorites show={showFavorites} styles={styles} />
      </Drawer>
    </>
  );
};

const mapSizesToProps = ({ width }: { width: number }) => ({
  isMobile: width < 800,
});

export default withSizes(mapSizesToProps)(MenuItem);
