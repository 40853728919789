import React from 'react';
import { Divider, Row, Col } from 'antd';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IMenuItem } from '@types';
import Condition from '@lib/condition';

type Props = {
  pairings: IMenuItem[];
  styles: {
    name: any;
    pairings: any;
    pairing: any;
    actions: any;
    'buy-button': any;
  };
};

const Pairings = ({ pairings = [], styles }: Props) => {
  const getPairingIcon = (item: IMenuItem) => {
    switch (item._type) {
      case 'dish':
        return <FontAwesomeIcon icon={['far', 'utensil-fork']} size="2x" />;
      case 'drink':
        return <FontAwesomeIcon icon={['far', 'glass-martini']} size="2x" />;
      case 'wine':
        return <FontAwesomeIcon icon={['far', 'wine-glass']} size="2x" />;
      case 'beer':
        return <FontAwesomeIcon icon={['far', 'glass']} size="2x" />;

      default:
        break;
    }
  };

  return (
    <Condition show={pairings?.length}>
      <Divider />
      <div className={classNames(styles.pairings, 'pairings')}>
        <h3 className={styles.name}>Pairings</h3>
        {pairings?.map((pairing) => (
          <div key={pairing.id} className={styles.pairing}>
            <Row gutter={16} justify="start">
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                {getPairingIcon(pairing)}
                <span>
                  {pairing.winery && `${pairing.winery} - `}
                  {pairing.name}
                </span>
              </Col>
              {pairing.link && (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  className={styles.actions}
                >
                  <a
                    className={styles['buy-button']}
                    href={`${pairing.link}?utm_source=livemenu`}
                    target="_blank"
                  >
                    <img src="/drizly-vector-logo.svg" width={28} />
                  </a>
                </Col>
              )}
            </Row>
          </div>
        ))}
      </div>
    </Condition>
  );
};

export default Pairings;
