import { createSelector } from 'reselect';
import _ from 'lodash';

import { IMenu, IMenuSection, IMenuItem, IStore, IFollow } from '@types';
import { searchMenuItem } from '../lib/utils';

const getFilter = (state: IStore, props: any) => state.filter;
const filterMenusBySlug = (state: any, props: any) => {
  return state.menus.filter((menu: IMenu) => menu.slug.current === props.slug);
};

const filterMenuBySearch = (menu: IMenu, query: string) => {
  const filteredMenu = _.cloneDeep(menu);
  if (filteredMenu && query) {
    filteredMenu.sections = filteredMenu.sections.filter(
      (section: IMenuSection) => {
        section.items = section.items.filter((item: IMenuItem) => {
          return searchMenuItem(item, query);
        });
        return section.items.length; //  || searchMenuSection(section, query)
      }
    );
  }
  return filteredMenu;
};

export const memoizedGetMenu = () =>
  createSelector([filterMenusBySlug, getFilter], (menus, filter) =>
    filterMenuBySearch(_.head(menus) as IMenu, filter.search)
  );
const getMenu = (state: IStore) => state.active.menu;
const getFollows = (state: IStore) => state.follows;
const isFavorite = (item: IMenuItem, follows: IFollow[]) =>
  follows.some(
    (follow: IFollow) =>
      ['dish', 'drink', 'beer', 'wine', 'spirit'].includes(
        follow.externalType
      ) &&
      follow.externalId === item._id &&
      follow.followed === true
  );

export const findMenuItems = (menu: IMenu, follows: IFollow[] = []) => {
  let items: IMenuItem[] = [];

  menu?.sections?.map((section) => {
    items = [...section.items.filter((item) => isFavorite(item, follows))];
  });

  return items;
};

export const getFollowedMenuItems = createSelector(
  [getMenu, getFollows],
  (menu: IMenu, follows: IFollow[]) => findMenuItems(menu, follows)
);
