import React, { ReactNodeArray } from 'react';
import classNames from 'classnames';
import { Card } from 'antd';

import { IMenuItem } from '@types';
import MenuItemImage from './menu-item-image';

type StyleProps = {
  item: string;
  photo: string;
  name: string;
  description: string;
};

type Props = {
  onClick: Function;
  item: IMenuItem;
  actions: ReactNodeArray;
  styles: StyleProps;
  className?: string;
};

const MenuItemCard = ({ item, actions, onClick, styles, className }: Props) => (
  <Card
    className={classNames(styles.item, 'menu-item', className)}
    cover={<MenuItemImage item={item} onClick={onClick} />}
    actions={actions}
  >
    <Card.Meta
      title={<h3 className={styles.name}>{item.name}</h3>}
      description={<p className={styles.description}>{item.description}</p>}
    />
  </Card>
);

export default MenuItemCard;
