import React from 'react';
import classNames from 'classnames';
import { Tag } from 'antd';
import Condition from '@lib/condition';

type Props = {
  tastes: string[];
  styles: {
    tastes: any;
  };
};

const Tastes = ({ tastes = [], styles }: Props) => {
  return (
    <Condition show={tastes?.length}>
      <div className={classNames(styles.tastes, 'tastes')}>
        {tastes?.map((taste) => (
          <Tag key={taste} color="blue">
            {taste}
          </Tag>
        ))}
      </div>
    </Condition>
  );
};

export default Tastes;
