import React, { ReactNodeArray } from 'react';
import { List } from 'antd';
import classNames from 'classnames';

import { IMenuItem } from '@types';

type StyleProps = {
  item: string;
  photo: string;
  name: string;
  description: string;
  listItem: string;
};

type Props = {
  onClick: Function;
  item: IMenuItem;
  actions: ReactNodeArray;
  styles: StyleProps;
  className?: string;
};

const MenuItemListItem = ({ item, actions, styles, className }: Props) => {
  return (
    <List.Item
      actions={actions}
      className={classNames(
        styles.item,
        styles.listItem,
        'menu-list-item',
        className
      )}
    >
      <List.Item.Meta
        title={<span className={styles.name}>{item.name}</span>}
        description={<p className={styles.description}>{item.description}</p>}
      />
    </List.Item>
  );
};

export default MenuItemListItem;
