import React from 'react';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { IMenuItem } from '@types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import withSizes from 'react-sizes';

type Props = {
  item: IMenuItem;
  enabled: boolean;
  active: boolean;
  title: string;
  color: string;
  icon: IconProp;
  onClick: Function;
  styles: any;
  isMobile: boolean;
};

const ActionButton = ({
  item,
  enabled,
  active,
  color,
  title,
  icon,
  onClick,
  styles,
  isMobile,
}: Props) => {
  return (
    <Button onClick={() => onClick(item)} block={true} type="ghost">
      <Tooltip title={title} trigger={isMobile ? 'focus' : 'hover'}>
        <FontAwesomeIcon
          size="1x"
          icon={icon}
          color={color}
          className={classNames({
            [styles.active]: active,
            [styles.enabled]: enabled,
          })}
        />
      </Tooltip>
    </Button>
  );
};

const mapSizesToProps = ({ width }: { width: number }) => ({
  isMobile: width < 800,
});

export default withSizes(mapSizesToProps)(ActionButton);
