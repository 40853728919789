import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { IStore } from '@types';
import { setLayout } from '@providers/actions';

import styles from './grid-list-switcher.module.less';

const GridListSwitcher = () => {
  const layout = useSelector((state: IStore) => state.display.layout);
  const dispatch = useDispatch();

  return (
    <Radio.Group defaultValue={layout} className={styles.gridListSwitcher}>
      <Radio.Button value="grid" onClick={() => dispatch(setLayout('grid'))}>
        <FontAwesomeIcon icon={['far', 'th']} size="1x" />
      </Radio.Button>
      <Radio.Button value="list" onClick={() => dispatch(setLayout('list'))}>
        <FontAwesomeIcon icon={['far', 'list']} size="1x" />
      </Radio.Button>
    </Radio.Group>
  );
};

export default GridListSwitcher;
