import React from 'react';
import { Card, Col, Row } from 'antd';
import classNames from 'classnames';

import { IMenu } from '@types';

import MenuFilter from './menu-filter';
import GridListSwitcher from '@components/extras/grid-list-switcher';

import styles from './menu.module.less';

type Props = {
  menu: IMenu;
};

const MenuHeader = ({ menu }: Props) => (
  <Card className={classNames(styles.header, 'menu-header')} bordered={false}>
    <GridListSwitcher />
    <Row gutter={32} className="no-padding">
      <Col xs={24} lg={12}>
        <h1 className={styles.name}>{menu.name}</h1>
        <MenuFilter />
      </Col>
    </Row>
  </Card>
);

export default MenuHeader;
