import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { graphql } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import { merge } from 'lodash';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import SEO from '@layout/seo';
import { Page, Section } from '@layout/page';
import PageBreadcrumb from '@layout/page-breadcrumb';
import Menu from '@components/menu';
import { useAuth } from '@providers/auth-provider';
import { fetchFollowsByAccount, setActiveMenu } from '@providers/actions';

import client from '@lib/sanity';
import MENU_QUERY from '@queries/sanity/update-menu.gql';
import { watchMenuQuery } from '@queries/sanity/menu';

import { IMenu, IPlace, IStore } from '@types';
import { filterMenuBySearch } from '@lib/utils';

type Props = {
  data: {
    place: IPlace;
    menu: IMenu;
  };
};

export const pageQuery = graphql`
  query Menu($placeId: String, $menuId: String) {
    place: sanityFoodPlace(id: { eq: $placeId }) {
      ...placeFields
    }
    menu: sanityMenu(id: { eq: $menuId }) {
      ...menuFields
    }
  }
`;

const MenuPage = ({ data }: Props) => {
  const [place] = useState(data.place);
  const [menu, setMenu] = useState(data.menu);
  const { search } = useSelector((state: IStore) => state.filter);
  const [filteredMenu, setFilteredMenu] = useState(menu);
  const { isAuthenticated } = useAuth();
  const dispatch: ThunkDispatch<IStore, any, AnyAction> = useDispatch();

  const routes = [
    {
      path: `/`,
      breadcrumbName: `Home`,
      icon: 'home',
    },
    {
      path: `/places/`,
      breadcrumbName: `Places`,
      icon: 'location',
    },
    {
      path: `/places/${place.slug.current}/`,
      breadcrumbName: place.name,
      icon: 'dish',
    },
    {
      path: `/places/${place.slug.current}/menus/${``}`,
      breadcrumbName: menu.name,
      icon: 'list',
    },
  ];

  const getIds = () => {
    let itemIds: string[] = [];

    const sectionIds = menu.sections.map((section) => {
      itemIds = section.items.map((item) => item._id);
      return section._id;
    });

    return {
      sectionIds,
      itemIds,
    };
  };

  const watch = () =>
    client
      .listen(
        watchMenuQuery,
        {
          placeId: place._id,
          menuId: menu._id,
          sectionIds: getIds().sectionIds,
          itemIds: getIds().itemIds,
        },
        { includeResult: false }
      )
      .subscribe(refresh);

  const updateMenu = (updatedMenu: IMenu) => {
    setMenu(merge(menu, updatedMenu));
    dispatch(setActiveMenu(menu));
  };

  const refresh = () =>
    setTimeout(
      () =>
        refetch().then((res) => {
          updateMenu(res.data.menu);
          setFilteredMenu(filterMenuBySearch(menu, search));
        }),
      500
    );

  const { refetch } = useQuery(MENU_QUERY, {
    variables: {
      id: menu._id,
    },
    skip: !place._id,
    onCompleted: (res) => {
      updateMenu(res.menu);
      setFilteredMenu(filterMenuBySearch(menu, search));
      watch();
    },
    fetchPolicy: 'no-cache',
  });

  const fetchFollows = useCallback(() => {
    dispatch(fetchFollowsByAccount(place.account._id));
  }, [dispatch, fetchFollowsByAccount]);

  useEffect(() => {
    setFilteredMenu(filterMenuBySearch(menu, search));
  }, [search, filterMenuBySearch]);

  useEffect(() => {
    if (isAuthenticated) fetchFollows();
  }, [fetchFollows, isAuthenticated]);

  return (
    <Page id="menu-page" color="white">
      <SEO
        title={`${place?.name} Menu - ${place?.address?.city}, ${place?.address?.state}`}
      />
      <Section>
        <PageBreadcrumb routes={routes} color="light" />
        <Menu menu={filteredMenu} />
      </Section>
    </Page>
  );
};

export default MenuPage;
