import React from 'react';
import { IMenuItem } from '@types';
import Condition from '@lib/condition';

import Diets from './diets';
import Pairings from './pairings';
import Prices from './prices';
import Tastes from './tastes';

type Props = {
  item: IMenuItem;
  show: boolean;
  styles: any;
};

const Details = ({ item, show, styles }: Props) => {
  return (
    <Condition show={show && item}>
      <div className={styles.typography}>
        <h2 className={styles.title}>Details</h2>
        <div className={styles.basics}>
          <h3 className={styles.name}>
            {item?.name} <Prices prices={item?.prices} styles={styles} />
          </h3>
          <p className={styles.description}>{item.description}</p>
          <Diets diets={item?.diets} styles={styles} />
          <Tastes tastes={item?.tastes} styles={styles} />
          <Pairings pairings={item?.pairings} styles={styles} />
        </div>
      </div>
    </Condition>
  );
};

export default Details;
