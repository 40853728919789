import React from 'react';
import { IMenuItemPrice } from '@types';
import Condition from '@lib/condition';

type Props = {
  prices: IMenuItemPrice[];
  styles: {
    price: any;
  };
};

const Prices = ({ prices = [], styles }: Props) => {
  return (
    <Condition show={prices.length}>
      <span className={styles.price}>
        {prices.map((price: IMenuItemPrice, i: number) => (
          <span key={i}>
            {`$`}
            {price.price} {i < prices.length - 1 && ` / `}
          </span>
        ))}
      </span>
    </Condition>
  );
};

export default Prices;
