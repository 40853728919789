import React, { useState } from 'react';
import { Button, Form, Rate, Input } from 'antd';

import { IMenuItem } from '@types';
import Condition from '@lib/condition';
import CheckTag from '@components/extras/check-tag';

type Props = {
  item: IMenuItem;
  show: boolean;
  styles: any;
};

const CheckTags = ({ tags }: { tags: string[] }) => (
  <>
    {tags.map((tag) => (
      <CheckTag key={tag}>{tag}</CheckTag>
    ))}
  </>
);

const Feedback = ({ item, show, styles }: Props) => {
  const [rating, setRating] = useState<number>();

  const positiveTags = [
    'overall flavor',
    'spiciness',
    'sweetness',
    'saltiness',
    'umami',
    'temperature',
  ];
  const negativeTags = [
    'underseasoned',
    'too spicy',
    'too sweet',
    'too salty',
    'too sour',
    'too bitter',
    'too cold',
    'too hot',
  ];
  const showPositiveTags = rating && rating > 3;
  const showNegativeTags = rating && rating <= 3;

  return (
    <Condition show={show}>
      <div className={styles.typography}>
        <h2 className={styles.title}>Feedback</h2>
        <p>
          Thank you for your feedback on this menu item! Your feedback is
          important to us, and it will be reviewed promptly.
        </p>
        <div className={styles.basics}>
          <Form className={styles['pad-vertical']}>
            <Form.Item>
              <h3 className={styles.name}>Rating</h3>
              <Rate onChange={setRating} allowClear={true} value={rating} />
            </Form.Item>
            {!!rating && (
              <Form.Item>
                <div className={styles['pad-vertical']}>
                  {showPositiveTags && (
                    <div>
                      <h4>What did you like?</h4>
                      <CheckTags tags={positiveTags} />
                    </div>
                  )}
                  {showNegativeTags && (
                    <div>
                      <h4>What could be better?</h4>
                      <CheckTags tags={negativeTags} />
                    </div>
                  )}
                </div>
              </Form.Item>
            )}
            <Form.Item>
              <h3 className={styles.name}>Comment / Suggestion</h3>
              <Input.TextArea rows={4} />
            </Form.Item>
            <Button type="primary" htmlType="submit" size="large">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </Condition>
  );
};

export default Feedback;
