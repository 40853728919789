import React, { ReactNode } from 'react';

type Props = {
  show: unknown;
  children: ReactNode;
};

const Condition = ({ show = false, children }: Props) => {
  return <>{!!show && children}</>;
};

export default Condition;
