import React, { SFC } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { Card, List } from 'antd';

import { IMenuSection, IMenuItem, IStore, IFollow } from '@types';
import MenuItem from './menu-item';

import styles from './menu.module.less';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

type Props = {
  section: IMenuSection;
};

const MenuSection: SFC<Props> = ({ section }) => {
  const layout = useSelector((state: IStore) => state.display.layout);

  return (
    <div className={classNames(styles.section, 'menu-section')}>
      <Card bordered={false}>
        {section.name && <h2 className={styles.name}>{section.name}</h2>}
        {section.headline && (
          <h6 className={styles.headline}>{section.headline}</h6>
        )}
        {section.description && (
          <div className={styles.description}>
            <BlockContent blocks={section.description} />
          </div>
        )}
        {layout === 'grid' && (
          <List
            size="large"
            grid={{
              gutter: 24,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
            dataSource={section.items}
            renderItem={(item: IMenuItem) => {
              return (
                <List.Item key={section.id}>
                  <MenuItem item={item} layout={layout} />
                </List.Item>
              );
            }}
          />
        )}
        {layout === 'list' && (
          <List
            itemLayout="horizontal"
            size="small"
            dataSource={section.items}
            bordered={true}
            renderItem={(item: IMenuItem) => {
              return <MenuItem item={item} layout={layout} key={section.id} />;
            }}
          />
        )}
      </Card>
    </div>
  );
};

export default MenuSection;
