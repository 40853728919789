import React from 'react';
import classNames from 'classnames';
import { Card, List, Empty } from 'antd';

import { IMenu, IMenuSection, IFollow } from '@types';
import MenuSection from './menu-section';
import MenuHeader from './menu-header';
import NoFilterResults from './no-filter-results';

import styles from './menu.module.less';

type Props = {
  menu: IMenu;
};

const Menu = ({ menu }: Props) => (
  <div className={classNames(styles.typography, styles.menu)}>
    <Card bordered={false}>
      <List
        header={<MenuHeader menu={menu} />}
        grid={{}}
        dataSource={menu.sections}
        renderItem={(section: IMenuSection) => (
          <List.Item className={styles.cards} key={section.id}>
            <MenuSection section={section} />
          </List.Item>
        )}
        locale={{
          emptyText: <Empty description="No menu items match your search." />,
        }}
      />
    </Card>
  </div>
);

export default Menu;
