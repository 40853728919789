import React from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { setSearch } from '@providers/actions';

type Props = {
  placeholder?: string;
  className?: string;
};

const Search = Input.Search;

const MenuFilter = ({
  placeholder = `e.g. 'organic' or 'gluten-free'`,
  className,
}: Props) => {
  const dispatch = useDispatch();

  return (
    <div className="menu-filter">
      <Search
        placeholder={placeholder}
        enterButton
        size="large"
        onChange={(e: any) => {
          dispatch(setSearch(e.target.value));
        }}
        className={cx('menu-filter-search', className)}
      />
    </div>
  );
};

export default MenuFilter;
