import React from 'react';
import { Tag } from 'antd';

import './check-tag.module.less';

const { CheckableTag } = Tag;

type Props = {};

type State = {
  checked: boolean;
};

class CheckTag extends React.Component<Props, State> {
  state: State = { checked: false };

  handleChange = (checked: boolean) => {
    this.setState({ checked });
  };

  render() {
    return (
      <CheckableTag
        {...this.props}
        checked={this.state.checked}
        onChange={this.handleChange}
        className="checktag"
      />
    );
  }
}

export default CheckTag;
