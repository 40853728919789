import imageUrlBuilder from '@sanity/image-url';

import sanityClient from '../lib/sanity';

const builder = imageUrlBuilder(sanityClient);

export class ImageService {
  public build(source: any): any {
    return builder.image(source);
  }
}

export default new ImageService();
