import React from 'react';
import classNames from 'classnames';
import { Tag } from 'antd';
import Condition from '@lib/condition';

type Props = {
  diets: string[];
  styles: {
    diets: any;
  };
};

const Diets = ({ diets = [], styles }: Props) => {
  return (
    <Condition show={diets?.length}>
      <div className={classNames(styles.diets, 'diets')}>
        {diets?.map((diet: any) => (
          <Tag key={diet}>{diet}</Tag>
        ))}
      </div>
    </Condition>
  );
};

export default Diets;
