import React from 'react';
import { IMenuItem } from '@types';
import Img from 'gatsby-image';
import imageService from '@services/image';

const MenuItemImage = ({
  item,
  onClick,
}: {
  item: IMenuItem;
  onClick: Function;
}) => {
  const preRenderedImage = item.image?.asset?.fluid;
  const fetchedImage = item.image?.asset?.url;
  const defaultImage = '/default.png';

  return (
    <>
      {(preRenderedImage && (
        <span onClick={() => onClick(item)}>
          <Img
            className="menu-item-image"
            alt={item.name}
            fluid={preRenderedImage}
            durationFadeIn={1000}
            backgroundColor="black"
          />
        </span>
      )) ||
        (fetchedImage && (
          <img
            className="menu-item-image"
            onClick={() => onClick(item)}
            alt={item.name}
            src={imageService.build(fetchedImage).size(400, 300)}
          />
        )) || (
          <img
            className="menu-item-image"
            alt={item.name}
            src={defaultImage}
            onClick={() => onClick(item)}
          />
        )}
    </>
  );
};

export default MenuItemImage;
