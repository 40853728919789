// Vendor
import React from 'react';
import { orderBy } from 'lodash';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';

// Local
import MenuItem from '@components/menu/menu-item';
import { IMenuItem } from '@types';
import Condition from '@lib/condition';
import { getFollowedMenuItems } from '@providers/selectors';

type Props = {
  show: boolean;
  styles: {
    favorites: any;
    typography: any;
    title: any;
    items: any;
    basics: any;
  };
};

const Favorites = ({ show, styles }: Props) => {
  const menuItems = useSelector(getFollowedMenuItems);
  const sortedFavorites = orderBy(menuItems, ['favoritedAt'], ['desc']);

  const count = menuItems?.length ? `(${menuItems?.length})` : ``;

  return (
    <Condition show={show}>
      <div className={styles.typography}>
        <h2 className={styles.title}>Favorites {count}</h2>
        <div className={styles.basics}>
          <Row gutter={16} className={styles.favorites}>
            <Col sm={24} lg={24} xl={24}>
              {menuItems && (
                <Row gutter={16} justify="start">
                  {sortedFavorites.map((item: IMenuItem) => (
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={8}
                      key={item._id}
                      className={styles.items}
                    >
                      <MenuItem item={item} layout="grid" actions={false} />
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          </Row>
          {!menuItems?.length &&
            `Add your favorite items, or ones you want to try!`}
        </div>
      </div>
    </Condition>
  );
};

export default Favorites;
